import request from '@/utils/request.js'
import { useMD5 } from '@/utils/crypto.js';

// 用户登录
export const login = (data) => {
    const { username, passwd, code } = data
    return request.post('/index/login', {
        username,
        password: useMD5().encrypt( passwd ),
		type: 1,
        code
    })
}

export const getMenuTree = () => {
    return request.post('/menu/tree')
}

export const logout = () => {
	return request.post('/index/logout');
}

export const getInfo = (data) => {
	return request.post('/user/getInfo', data)
}

export const getDeptTree = (data) => {
	return request.post('/dept/tree', data)
}

export const getCaptcha = () => {
	return request.get('/index/getCaptcha')
}
